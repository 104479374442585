<template>
  <div>
    <div class="header"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="color: #fff">
          <h3 class="text-center">Status Transaksi</h3>
          <p class="text-center">{{ timeStampFormat(detail.no_transaksi) }}</p>
        </div>
        <div class="col-xl-12">
          <div class="card mb-3" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px">
              <strong>Total Transaksi</strong>
              <h2 style="color: #e94f07; font-weight: bolder">
                {{ currencyFormat(detail.payment_amount) }}
              </h2>
            </div>
            <div class="row" style="padding: 0px 15px">
              <div class="col-6">Nomor</div>
              <div class="col-6 text-right">
                <strong>
                  <LazyLoad :data-to-display="detail.no_transaksi"></LazyLoad>
                </strong>
              </div>
            </div>
            <div class="alert f-s-13">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="card" style="border-radius: 10px; padding: 15px">
                    <div class="row mb-2">
                      <div class="col-6">Nama</div>
                      <div class="col-6 text-right">
                        <strong>
                          <LazyLoad
                            :data-to-display="detail.customer_name"
                          ></LazyLoad>
                        </strong>
                      </div>
                    </div>
                    <hr style="margin: 0px 0px 10px 0px" />
                    <div class="row">
                      <div class="col-6">NPWRD</div>
                      <div class="col-6 text-right">
                        <strong>
                          <LazyLoad :data-to-display="detail.npwrd"></LazyLoad>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-2">
                  <label class="mb-0">Jenis Kegiatan : </label>
                  <strong>
                    <LazyLoad
                      :data-to-display="detail.category_name"
                    ></LazyLoad>
                  </strong>
                </div>
                <div class="col-md-12" style="margin-bottom: -15px">
                  <label class="mb-0">Satuan : </label>
                  <strong>
                    <LazyLoad
                      :data-to-display="this.currencyFormat(detail.cost)"
                    ></LazyLoad>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="border-radius: 15px">
            <div class="alert f-s-13">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <label class="mb-0">Periode Pembayaran : </label>

                  <strong>
                    <LazyLoad
                      :data-to-display="detail.additional_details"
                    ></LazyLoad>
                  </strong>
                </div>
                <div class="col-md-12" style="margin-bottom: -15px">
                  <label class="mb-0">Status Transaksi : </label>
                  <button
                    v-if="detail.transaction_status == '1'"
                    type="button"
                    class="btn btn-success btn-block"
                  >
                    <i class="fas fa-check"></i>
                    Lunas
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-warning btn-block"
                  >
                    <i class="fas fa-times"></i>
                    Belum Lunas
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import axios from "axios";
import moment from "moment";
import LazyLoad from "../../components/loader/LazyLoad.vue";

export default {
  components: {
    LazyLoad,
  },
  data() {
    return {
      detail: {
        transaction_id: "",
        customer_id: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        payment_amount: "",
        additional_details: "",
        transaction_date: "",
        transaction_status: "",
        no_transaksi: "",
        created_name: "",
        cost: "",
      },
      loading: false,
      notImage: false,
    };
  },
  computed: {
    rwrstrongata() {
      return `RW ${this.detail.hamlet} / RT ${this.detail.neighbourhood}`;
    },
    statusTransaksi() {
      if (this.detail.transaction_status == "1") {
        return "Lunas";
      } else {
        return "Belum Lunas";
      }
    },
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.detail.transaction_id = id;
      this.fetchData();
    }
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    fetchData() {
      axios
        .get("/v1/transactions/detail/" + this.detail.transaction_id)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY, HH:mm:ss");
    },
    currencyFormat(value) {
      if (!value) return "";
      return "Rp. " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    rtrwData() {
      return `RW ${this.detail.hamlet} / RT ${this.detail.neighbourhood}`;
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #e94f07;
  color: #fff;
  border-radius: 0px 0px 30px 30px;
  margin-bottom: -130px;
  z-index: 0;
}
.container {
  padding: 20px;
}
</style>